import {BlockEmbed} from 'quill/blots/block';
import {v4 as uuidv4} from 'uuid';
import {PromotionSliderComponent} from '@app/promotion-slider/promotion-slider.component';
import {ViewContainerRef} from '@angular/core';
import {Promotion} from '@app/models';

export class PromosBlot extends BlockEmbed {
  static override blotName = 'promos';
  static override tagName = 'div';
  static override className = 'promo-slider';

  static override create(
    rawValue?: unknown,
    viewContainerRef?: ViewContainerRef,
    promotions: Promotion[] = []
  ) {
    const node = super.create() as HTMLElement;
    const category = rawValue && typeof rawValue === 'string' ? rawValue : null; // Extract category if provided

    node.dataset['promotions'] = 'true';

    if (category) {
      node.dataset['category'] = category;
    }

    const UUID = uuidv4(); // form instance ID
    node.id = `promo-instance-${UUID}`;

    // Create a placeholder element
    const placeholder = document.createElement('div');
    placeholder.className = 'promo-slider-placeholder';
    // placeholder.innerText = `Loading Promotions...`; // Placeholder text
    node.appendChild(placeholder); // Append the placeholder to the node

    return node;
  }

  static override value(domNode: HTMLElement) {
    return domNode.dataset['category'];
  }
}
